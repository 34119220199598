<script>
import {bus} from "@/router/bus";

export default {
  name: "AppDropFile",
  props: {
    label: String,
    temp_id: Number,
    accept: { type: String, default: () => "" },
    maxSize: { type: Number, default: 5 * 1024 * 1024},
    hideArea: Boolean
  },

  data() {
    return {
      files: [],
      upLoadedFiles: [],
      isDragging: false,
      showProgress: false,
      disabled: false,
      errorMessage: null
    };
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth
    },

    allowedExtensions() {
      return this.accept
        .replace(/\s+/g, '')
        .split(',')           
        .map(ext => ext.replace('.', '').toLowerCase());
    },

    showFiles(){
      return this.windowSize > 743 ? this.upLoadedFiles : this.upLoadedFiles.slice(1, 6)
    }
  },

  mounted(){
    bus.$on('resetFiles', () =>{
      this.resetFileList()
    })
  },

  methods: {
    selectFiles() {
      this.$refs.fileInput.click()
    },

    uploadFile(files) {
      if (files.length === 0) return;
      const formData = new FormData()
      formData.append("temp_id", this.temp_id)

      this.showProgress = true

      for (let i = 0; i < files.length; i++) {
        formData.append(files[i].name, files[i])
        if (!this.files.some((e) => e.name === files[i].name)) {
          this.files.push(files[i].name)
        }
      }

      this.$postapi(this.$address + this.$getters.ftpposttest.uri, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        for (let i = 0; i < files.length; i++) {
          if (!this.upLoadedFiles.some((e) => e.name === files[i].name)) {
            this.upLoadedFiles.push(files[i].name)
          }
        }
        this.$emit('fileUploaded', this.upLoadedFiles)
        this.showProgress = false
        this.files = []
        this.$refs.fileInput.value = '';
      });
    },

    onSelectFile(e) {
      const files = Array.from(e.target.files);
      const { validFiles, errors } = this.validateFiles(files, this.allowedExtensions, this.maxSize);

      if (errors.length) {
        this.disabled = true;
        this.errorMessage = errors.join('; ');
      } else {
        this.disabled = false;
        this.errorMessage = '';
      }

      if (validFiles.length) {
        this.uploadFile(validFiles);
        this.$emit('getArticleFile', files[0])
      }
    },

    deleteFile(idx) {
      this.upLoadedFiles.splice(idx, 1)
      this.$emit('removeArticleFile')
      this.$refs.fileInput.value = '';
    },

    onDragOver(e) {
      this.disabled = false,
      this.errorMessage = null
      this.isDragging = true
      e.dataTransfer.dropEffect = "copy"
    },

    onDragLeave() {
      this.isDragging = false
    },

    onDrop(e) {
      this.isDragging = false;
      const files = Array.from(e.dataTransfer.files);
      const { validFiles, errors } = this.validateFiles(files, this.allowedExtensions, this.maxSize);

      if (errors.length) {
        this.disabled = true;
        this.errorMessage = errors.join('; ');
      } else {
        this.disabled = false;
        this.errorMessage = '';
      }

      if (validFiles.length) {
        this.uploadFile(validFiles);
        this.$emit('getArticleFile', files[0])
      }
    },

    validateFiles(files, allowedExtensions, maxSize) {
      const validFiles = [];
      const formatErrors = [];
      const sizeErrors = [];

      files.forEach(file => {
        const extension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(extension)) {
          formatErrors.push(`"${file.name}"`);
          return;
        }

        if (file.size > maxSize) {
          sizeErrors.push(`"${file.name}"`);
          return;
        }

        validFiles.push(file);
      });

      const messages = [];

      if (formatErrors.length) {
        const filesList = formatErrors.join(', ');
        const formatMessage = `Файл${formatErrors.length > 1 ? 'ы' : ''} ${filesList} ` +
                              `имеет${formatErrors.length > 1 ? 'ют' : ''} недопустимый формат. ` +
                              `Разрешены только: ${allowedExtensions.join(', ')}`;
        messages.push(formatMessage);
      }

      if (sizeErrors.length) {
        const filesList = sizeErrors.join(', ');
        const sizeMessage = `Файл${sizeErrors.length > 1 ? 'ы' : ''} ${filesList} ` +
                            `превышает${sizeErrors.length > 1 ? 'ют' : ''} максимальный размер ${maxSize / (1024 * 1024)} MB`;
        messages.push(sizeMessage);
      }

      return {
        validFiles,
        errors: messages
      };
    },

    resetFileList(){
      this.upLoadedFiles = [];
      this.files = [];
    },
  },
};
</script>

<template>
  <div class="drop-file">
    <p class="drop-file__title" v-if="!hideArea">{{ label }}</p>
    <div class="drop-file__area">
    
      <div
        :class="[isDragging ? 'drop-file__main--progress' : '', disabled ? 'drop-file__main--disabled' : '', hideArea ? 'drop-file__main--hidden' : '']"
        class="drop-file__main"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
      >
        <div v-if="!isDragging">
          <p v-if="windowSize > 743" class="drop-file__main-description">
            Перетащите файл или
            <span class="drop-file__main-link" @click="selectFiles">
              загрузите с диска
            </span>
          </p>
          <span v-else class="drop-file__main-link" @click="selectFiles">
            Выбрать файл
          </span>
          <div v-if="disabled" class="drop-file__main-description-comment">
            {{ errorMessage }}
          </div>
        </div>
        <div v-else>
          <p class="drop-file__main-description">Разместите файл здесь</p>
        </div>
      
        <input
          class="drop-file__input"
          type="file"
          name="file"
          :accept="accept"
          ref="fileInput"
          multiple
          @change="onSelectFile"
        />
      </div>
      <div class="drop-file__items">
        <div class="drop-file__items-load" v-if="showProgress">
          <div
            class="drop-file__item"
            v-for="(file, index) in files"
            :key="index"
          >
            <p class="drop-file__item-name">{{ file }}</p>

            <div
              :class="showProgress ? 'drop-file__loader--progress' : ''"
              class="drop-file__loader"
            >
              <img
                src="../../assets/img/icons/cancel-grey.svg"
                alt="Close icon"
              />
            </div>
          </div>
        </div>
        <div v-if="!hideArea" class="drop-file__items-load drop-file__items-load--loaded">
          <div
            class="drop-file__item"
            v-for="(file, index) in showFiles"
            :key="index"
          >
            <p class="drop-file__item-name">{{ file }}</p>
            <img
              src="../../assets/img/icons/cancel.svg"
              alt="Close icon"
              @click="deleteFile(index)"
            />
          </div>
        </div>
        <div class="drop-file__hidden-area-file" v-else>
          <span class="drop-file__title">Файл для поиска</span>
           <div
            class="drop-file__item"
            v-for="(file, index) in this.upLoadedFiles"
            :key="index"
          >
            <p class="drop-file__item-name drop-file__item-name--big">{{ file }}</p>
            <img
              src="../../assets/img/icons/cancel.svg"
              alt="Close icon"
              @click="deleteFile(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
input {
  display: none;
}

.drop-file {
  &__title {
    color: var(--grey-70);
    margin-bottom: 4px;

    @include font-description-0;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;
    border-radius: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23C1CDD6; stroke-width: 4; stroke-dasharray: 14 15.5; stroke-linejoin:round'/></svg>");
    transition: 0.2s;

    @media (max-width: $tablet-width){
      height: 158px;
    }

    &--progress {
      background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23305CA8; stroke-width: 4; stroke-dasharray: 14 15.5; stroke-linejoin:round'/></svg>") !important;
      background: var(--blue-10);
    }

    &--disabled {
      background-color: var(--grey-10);
    }

    &--hidden{
      display: none;
    }

    &-description {
      color: var(--grey-50);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.7px;
      pointer-events: none;
      text-align: center;

      display: flex;
      flex-direction: column;

      &-comment {
        text-align: center;
        color: var(--grey-60);
        @include font-description-1;
        max-width: 450px;
      }
    }

    &-link {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.7px;
      color: var(--blue-70);
      pointer-events: all;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__items {
    position: relative;

    &-load {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 17px 64px;
      max-height: 200px;
      margin-top: 20px;
      margin-bottom: 17px;
      justify-content: flex-start;

      @media (max-width: $mobile-width){
        max-height: unset;
      }

      &--loaded {
        flex-direction: column;
        margin-bottom: unset;

        @media (max-width: $mobile-width){
          flex-direction: column-reverse;
        }
      }
    }
  }

  &__item {
    max-width: 45%;
    display: flex;
    gap: 0 8px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;

    @media (max-width: $mobile-width){
      max-width: unset;
    }

    &-name {
      color: var(--grey-60);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.14px;
      word-break: break-all;

      &--big{
        @include font-body-1;
        color: var(--grey-70);
      }
    }
  }

  &__loader {
    position: relative;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--progress {
      &:before {
        position: absolute;
        content: "";
        border: 1px solid var(--grey-20);
        border-top: 1px solid var(--grey-60);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        animation: spin 2s linear infinite;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &__hidden-area-file{
    margin-bottom: 32px;

    .drop-file__item{
      max-width: fit-content;

      &-name{
        @include font-body-1;

        @media (max-width: $mobile-width){
          @include font-body-2;
        }
      }
    }

  }
}
</style>